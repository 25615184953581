var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FileDialog',{ref:"fileViewer",attrs:{"url":_vm.selectedUrl}}),_c('base-data-table-cards',{ref:"list",attrs:{"headers":_vm.headers,"headers_small":_vm.headers_small,"data":_vm.items,"server-items-length":_vm.total,"totalItems":_vm.total,"title":_vm.$tc('payroll', 1)},on:{"update":_vm.fetchInvoices},scopedSlots:_vm._u([{key:"item_number",fn:function(item){return [_c('a',{on:{"click":function($event){return _vm.showFactura(item)}}},[_vm._v(_vm._s(item.number))])]}},{key:"item_date",fn:function(item){return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.created_at), "dateShort"))+" ")]}},{key:"item_from",fn:function(item){return [_vm._v(" "+_vm._s(item.from.fullname)+" ")]}},{key:"item_to",fn:function(item){return [_vm._v(" "+_vm._s(item.to.fullname)+" ")]}},{key:"item_type",fn:function(item){return [_vm._v(" "+_vm._s(_vm.$t("invoices.types." + item.type))+" ")]}},{key:"item_total_price",fn:function(item){return [_vm._v(" "+_vm._s(_vm.$n(item.total_price, "currency"))+" ")]}},{key:"item_actions",fn:function(item){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"href":item.url,"target":"_blanck"}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"margin-top":"0px !important"},attrs:{"small":""}},[_vm._v("$eye")]),_c('span',[_vm._v(_vm._s(_vm.$t("see", { name: _vm.$tc("invoices.tax", 1) })))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                name: 'InvoicesUpdateForm',
                params: { id: item.id },
              })}}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"margin-top":"0px !important"},attrs:{"small":""}},[_vm._v(" $edit ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit", { name: _vm.$tc("invoices.tax", 1) })))])],1)],1)],1)]}},{key:"item_from_to",fn:function(item){return [_c('div',[_c('b',[_vm._v(" "+_vm._s(item.from.fullname)+" ")])]),_c('div',[_vm._v(" "+_vm._s(item.to.fullname)+" ")])]}},{key:"item_type_chip",fn:function(item){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-avatar',_vm._g({staticStyle:{"margin":"0 auto","font-size":"25px","text-transform":"uppercase","color":"#272727"},attrs:{"color":"primary","size":30}},on),[_c('span',[_vm._v(_vm._s(_vm.nameAvatar(item.type)))])])]}}],null,true)},[_vm._v(" "+_vm._s(item.type)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }