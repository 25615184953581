<template>
  <div>
    <FileDialog ref="fileViewer" :url="selectedUrl" />
    <base-data-table-cards
      :headers="headers"
      :headers_small="headers_small"
      :data="items"
      :server-items-length="total"
      :totalItems="total"
      :title="$tc('payroll', 1)"
      @update="fetchInvoices"
      ref="list"
    >
      <template v-slot:[`item_number`]="item">
        <a @click="showFactura(item)">{{ item.number }}</a>
      </template>
      <template v-slot:[`item_date`]="item">
        {{ $d(new Date(item.created_at), "dateShort") }}
      </template>

      <template v-slot:[`item_from`]="item">
        {{ item.from.fullname }}
      </template>
      <template v-slot:[`item_to`]="item">
        {{ item.to.fullname }}
      </template>

      <template v-slot:[`item_type`]="item">
        {{ $t("invoices.types." + item.type) }}
      </template>
      <template v-slot:[`item_total_price`]="item">
        {{ $n(item.total_price, "currency") }}
      </template>
      <template v-slot:[`item_actions`]="item">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item :href="item.url" target="_blanck">
              <v-icon class="mr-2" small style="margin-top: 0px !important"
                >$eye</v-icon
              >
              <span>{{ $t("see", { name: $tc("invoices.tax", 1) }) }}</span>
            </v-list-item>
            <v-list-item
              @click="
                $router.push({
                  name: 'InvoicesUpdateForm',
                  params: { id: item.id },
                })
              "
            >
              <v-icon class="mr-2" small style="margin-top: 0px !important">
                $edit
              </v-icon>
              <span> {{ $t("edit", { name: $tc("invoices.tax", 1) }) }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item_from_to`]="item">
        <div>
          <b>
            {{ item.from.fullname }}
          </b>
        </div>
        <div>
          {{ item.to.fullname }}
        </div>
      </template>
      <template v-slot:[`item_type_chip`]="item">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-avatar
              color="primary"
              :size="30"
              style="
                margin: 0 auto;
                font-size: 25px;
                text-transform: uppercase;
                color: #272727;
              "
              v-on="on"
            >
              <span>{{ nameAvatar(item.type) }}</span>
            </v-avatar>
          </template>
          {{ item.type }}
        </v-menu>
      </template>
    </base-data-table-cards>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "InvoiceTable",
  //props: ["items", "headers", "total"],
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default() {
        return [
          {
            text: this.$t("invoices.number"),
            sortable: false,
            value: "number",
            align: "center",
          },
          {
            text: this.$t("invoices.date"),
            sortable: false,
            value: "date",
            align: "center",
          },
          {
            text: this.$t("invoices.type"),
            sortable: false,
            value: "type",
            align: "center",
          },
          {
            text: this.$t("invoices.from"),
            sortable: false,
            value: "from",
            align: "center",
          },
          {
            text: this.$t("invoices.to"),
            sortable: false,
            value: "to",
            align: "center",
          },
          {
            text: this.$t("invoices.total"),
            sortable: false,
            value: "total_price",
            align: "center",
          },
          /*
          {
            text: this.$t("payment_method"),
            sortable: false,
            value: "payment_method",
            align: "center",
          },
          */
          {
            text: this.$t("actions"),
            sortable: false,
            value: "actions",
            align: "center",
          },
        ];
      },
    },
  },
  data() {
    return {
      firstFetch: true,
      selectedUrl: "",
      items: null,
      total: null,
      headers_small: [
        { value: "type_chip", class: "ml-3", no_col: true },
        { value: "from_to", cols: 6 },
        { value: "total_price" },
      ],
    };
  },
  watch: {
    filters: {
      handler() {
        this.fetchInvoices();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchInvoices();
  },
  methods: {
    ...mapActions("invoices", ["getInvoices"]),
    fetchInvoices(options) {
      this.items = null;
      this.getInvoices({
        filters: this.filters,
        pagination: options,
      }).then((response) => {
        this.items = response.data;
        this.total = response.total;
        if (this.total == 0 && this.firstFetch) this.$emit("empty2");
        this.firstFetch = false;
      });
    },
    nameAvatar(item) {
      //console.log(arr);
      let arr = item.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });

      return nom;
    },
    showFactura(invoice) {
      console.log(invoice);
      this.selectedUrl = invoice.url;
      this.$refs.fileViewer.open();
    },
  },
};
</script>
